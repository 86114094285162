import * as ReactDOM from "react-dom";
import App from "./App";
import React from "react";
import 'formiojs/dist/formio.full.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/style.scss';
import 'react-tabs/style/react-tabs.css';
import "react-table/react-table.css";
import 'react-image-lightbox/style.css';
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-calendar/dist/Calendar.css";
import './extend';
import 'react-vertical-timeline-component/style.min.css';
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/theme.css';
import * as moment from "moment";
const root = document.getElementById('root');
const aFrame = require('aframe/dist/aframe-v1.1.0');
import 'jquery'
import 'imagesloaded'
require('imagesloaded').makeJQueryPlugin( $ );
require('zurb-twentytwenty/js/jquery.event.move');
require('zurb-twentytwenty/js/jquery.twentytwenty');
if(root) {
    ReactDOM.render(
    <App/>,
    root
);
}
