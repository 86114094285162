import React, {Component} from 'react';
import GalleryList from "../components/gallery/GalleryList";
import RouterStore from "../stores/RouterStore";
import {inject} from "mobx-react";
import WPService from "../services/WPService";


interface IGalleryListPageProps {
    match?: any;
    routing?: RouterStore,
}


interface IGalleryListPageState {
    year: number;

}

@inject('routing')
export default class GalleryListPage extends Component<IGalleryListPageProps, IGalleryListPageState> {
    constructor(props) {
        super(props);
        this.state = {
            year: parseInt(this.props.match.params.year)
        }
        this.onSelectedGallery = this.onSelectedGallery.bind(this);
    }

    loadGallery() {

    }

    render() {
        return (
            <div className="gallery-list-page">
                <h1 className="center">Pildigalerii {this.state.year}</h1>
                <GalleryList showThreeImageInRow={true} year={this.state.year} showDate={true} onClickGallery={this.onSelectedGallery}/>
            </div>
        );
    }

    onSelectedGallery(gallery) {
        this.props.routing.push(`/galleries/${gallery.slug}`);
    }
}
