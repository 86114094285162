import {Component} from "react";
import * as React from "react";
import {inject} from "mobx-react";
import Menu from "../menu";
import MenuItem from "../menu/MenuItem";
import * as moment from "moment";

interface IMenuRightProps {
    latestGallery?: any;
    latestVideo?: any;
    iconPosition?: string;
}

@inject('latestGallery')
@inject('latestVideo')
export default class MenuRight extends Component<IMenuRightProps, {}> {
    render() {
        const {latestGallery, latestVideo} = this.props;
        let {iconPosition} = this.props;
        if(!iconPosition) {
            iconPosition = 'left';
        }
        const currentYear = moment().year();
        return (
            <>
                <MenuItem className="media-link" color={'green'} label={"Meedia"} border={true} icon={'photo-gallery'} iconPosition={iconPosition} link={`/meedia-${currentYear}`} >
                    <MenuItem link={`/meedia-${currentYear}`} forceLink={true}>
                        {latestGallery && latestGallery.thumbnail_url && <img src={latestGallery.thumbnail_url} alt={''}/>}
                    </MenuItem>
                    <MenuItem link={`/meedia-${currentYear}`} forceLink={true}>
                        {latestVideo.image && <img src={latestVideo.image} alt={""}/>}
                    </MenuItem>
                </MenuItem>
                <MenuItem color={'green'} label={"Top 10 videod"} link={`/meedia-top-10-${currentYear - 1}`} border={true} icon={'top-10'} iconPosition={iconPosition}/>
                <MenuItem color={'green'} label={"Lingid"} border={true} link={'/lingid'} icon={'internet'} iconPosition={iconPosition}/>
                <MenuItem color={'green'} label={"Kasulikud"} border={true} link={'/kasulikud'} icon={'list-view'} iconPosition={iconPosition}/>
                <MenuItem color={'green'} label={"Kurtide muuseum"} border={true} link={'/kurtide-muuseum'} icon={'star'} iconPosition={iconPosition}/>
                <MenuItem color={'green'} label={"Arhiiv"} border={true} link={`/arhiiv/${currentYear}`} icon={'wallet'} iconPosition={iconPosition}/>
            </>
        )
    }
}
