import {Component} from "react";
import * as React from "react";
import {inject} from "mobx-react";
import {Link} from "react-router-dom";
import MenuLeft from "./MenuLeft";
import LogosLeft from "./LogosLeft";

interface ISidebarLeftProps {
    eventCategories?: any[];
}

@inject('eventCategories')
export default class SidebarLeft extends Component<ISidebarLeftProps, {}> {
    render() {
        return (
            <div className="sidebar-left">
                <div className="sticky-spacer"/>
                <div className="sticky-content">
                    <div className="header">
                        <Link to="/">
                            <div className="logo-img"/>
                        </Link>
                    </div>
                    <MenuLeft />
                    <LogosLeft />
                </div>
            </div>
        )
    }
}
