import {Component} from "react";
import * as React from "react";

interface IMenuProps {
    center?: boolean
    className?: string
}

export default class Menu extends Component<IMenuProps, {}>{
    render() {
        let classNames = ['menu'];
        const {center} = this.props;
        if(this.props.className) {
            classNames.push(this.props.className);
        }
        if(center) {
            classNames.push('menu-align-center')
        }
        return (
            <div className={classNames.join(" ")}>
                {this.props.children}
            </div>
        );
    }
}