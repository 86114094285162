import {Component} from "react";
import * as React from "react";
import Icon from "../icon";

interface IScrollToTop {
}

interface IScrollToState {
    element?: HTMLElement;
}

export default class ScrollToTop extends Component<IScrollToTop, IScrollToState> {
    private element: HTMLElement = null;

    constructor(props) {
        super(props);
        this.initRef = this.initRef.bind(this);
        this.scrollEvent = this.scrollEvent.bind(this);
        this.click = this.click.bind(this);
        this.state = {};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps: Readonly<IScrollToTop>, prevState: Readonly<IScrollToState>, snapshot?: any) {

    }

    render() {
        return (
            <div className="scroll-to-top-button hidden" onClick={this.click} ref={this.initRef}>
                <Icon icon="up"/>
            </div>
        )
    }

    initRef(e) {
        this.element = e;
        if(this.element) {
            this.element.parentElement.addEventListener('scroll', this.scrollEvent);
            this.scrollEvent();
        }
    }

    scrollEvent() {
        if(this.element.parentElement.scrollTop > 20) {
            this.element.classList.remove('hidden');
        }
        else {
            this.element.classList.add('hidden');
        }
    }

    click() {
        this.element.parentElement.scrollTop = 0;
    }
}
