declare global {
  interface Array<T> {
    //next(item: T) : T;
    first: T;
    last: T;
  }
}

// Array.prototype.next = function (item) {
//     const index = this.indexOf(item);
//     if(index === -1) {
//         return null;
//     }
//     const nextIndex = index + 1;
//     return nextIndex < this.length ? this[nextIndex] : null;
// };


Object.defineProperties(Array.prototype, {
     first: {
         get: function () {
             if(this.length > 0) {
                 return this[0];
             }
             return null;
         }
     },
     last: {
         get: function () {
             if (this.length > 0) {
                 return this[this.length - 1];
             }
             return null;
         }
     }
});

export {};
