import React, {Component} from 'react';
import RouterStore from "../stores/RouterStore";
import {inject} from "mobx-react";
import WPService from "../services/WPService";
import NotFound from "./NotFound";
import MenuItem from "../components/menu/MenuItem";
import Menu from "../components/menu";
import HtmlContent from "../components/html-content";

interface IMediaTop10Props {
    match?: any;
    routing?: RouterStore
    wpService?: WPService,
}

interface IMediaTop10State {
    year: number;
    data?: any;
}

@inject('routing')
@inject('wpService')
export default class MediaTop10 extends Component<IMediaTop10Props, IMediaTop10State> {
    constructor(props) {
        super(props);
        this.state = {
            year: parseInt(props.match.params.year),
        }
        this.loadPage();
    }

    loadPage(year?: number) {
        if(!year) {
            year = this.state.year;
        }
        this.props.wpService.getPages(1, 1, `meedia-top-10-${year}`).then(response => {
            this.setState({data: response.data.length === 0 ? null : response.data[0]});
        });
    }

    componentDidUpdate(prevProps: Readonly<IMediaTop10Props>, prevState: Readonly<IMediaTop10State>, snapshot?: any) {
        if(prevState.year !== this.state.year) {
            this.setState({data: undefined});
            this.loadPage();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            year: parseInt(nextProps.match.params.year),
        }
    }

    render() {
        if(this.state.data === undefined) {
            return "";
        }
        if (this.state.data === null) {
            return <NotFound/>
        }
        return (
            <div>
                <h2 className="center">TOP 10 videod</h2>
                <Menu className={'item-inline-block'} center={true}>
                    {[2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015].map(year => (
                        <MenuItem key={year} label={String(year)} border={true} color={this.state.year === year ? 'green' : 'blue'} onClick={this.onSelectYear.bind(this, year)}/>)
                    )}
                </Menu>
                <HtmlContent content={this.state.data.content.rendered}/>
            </div>
        );
    }

    onSelectYear(year) {
        this.props.routing.push(`/meedia-top-10-${year}`);
    }
}
