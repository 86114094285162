import React, {Component} from 'react';
import NotFound from "./NotFound";
import PostDetail from "../components/post/PostDetail";
import RouterStore from "../stores/RouterStore";
import {inject} from "mobx-react";
import WPService from "../services/WPService";
import HtmlContent from "../components/html-content";
import { Roller } from "react-awesome-spinners";

interface IPageProps {
    match: any,
    routing?: RouterStore,
    wpService?: WPService,
}

interface IPageState {
    data?: any
}

@inject('routing')
@inject('wpService')
export default class Page extends Component<IPageProps, IPageState> {

    constructor(props) {
        super(props);
        this.state = {};
        this.loadPage();
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<IPageProps>, nextContext: any) {
        if(this.props.match.params.slug !== nextProps.match.params.slug) {
            this.loadPage(nextProps.match.params.slug);
        }
    }

    loadPage(slug?: string) {
        if(!slug) {
            slug = this.props.match.params.slug;
        }
        this.props.wpService.getPages(1, 1, slug).then(response => {
            this.setState({data: response.data.length === 0 ? null : response.data[0]});
        });
    }


    render() {
        const {data} = this.state;
        if(this.state.data === undefined) {
            return <Roller/>;
        }
        if (this.state.data === null) {
            return <NotFound/>
        }
        return (
            <div>
                <h2 className="news-item-header" dangerouslySetInnerHTML={{__html: data.title.rendered}}/>
                <HtmlContent content={data.content.rendered}/>
            </div>
        );
    }

    goToIndex() {
        this.props.routing.goBack();
    }
}
