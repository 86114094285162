import {Component} from "react";
import * as React from "react";
import {inject} from "mobx-react";

const EestiKurtidaLiitLogo = require("../../assets/logos/EKL logo 2020.png");
const TartuLogo = require("../../assets/logos/Tartu logo 2023.png");
const TartuKodaLogo = require("../../assets/logos/tartukoda.png");

@inject('eventCategories')
export default class LogosLeft extends Component {
    render() {
        return (
            <div className="logos">
                <div>
                    <a href="http://www.ead.ee" target="_blank"><img src={EestiKurtidaLiitLogo} alt={"Eesti Kurtide Liit logo"}/></a>
                    <a href="https://tartukoda.ee" target="_blank"><img src={TartuKodaLogo} alt={"Tartu Koda logo"}/></a>
                    <a className="tartu-logo" href="https://www.tartu.ee" target="_blank"><img src={TartuLogo} alt={"Tartu logo"}/></a>
                </div>
            </div>
        )
    }
}
