import {Component, default as React} from "react";
import Icon from "../icon";
import Lightbox from 'react-image-lightbox';
import YouTube from 'react-youtube';
import ImageGallery from 'react-image-gallery';
import HtmlContent from "../html-content";

const PREFIX_URL = 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/';


interface IPostDetailProps {
    data: any;
    id?: number,
    icon?: string
    title?: any,
    imgSrc?: string,
    content?: any,
    onBackClick?: (e: any) => void
}

const images = [
    '//placekitten.com/1500/500',
    '//placekitten.com/4000/3000',
    '//placekitten.com/800/1200',
    '//placekitten.com/1500/1500',
];

interface IPostDetailState {


}


export default class PostDetail extends Component<IPostDetailProps, IPostDetailState> {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState(props?): IPostDetailState {
        if(!props) {
            props = this.props;
        }

        return {

        }
    }


    render() {
        const {data} = this.props;


        return (
            <div className="news-detail">
                <h2 className="news-item-header" dangerouslySetInnerHTML={{__html: data.title.rendered}}/>
                <HtmlContent className={'news-item-content'} content={data.content.rendered}/>
            </div>
        );
        // const {icon, title, imgSrc, content, onBackClick} = this.props;
        // const { photoIndex, isOpen } = this.state;
        // return (
        //     <div className="news-detail">
        //         {isOpen && (
        //             <Lightbox
        //                 mainSrc={images[photoIndex]}
        //                 nextSrc={images[(photoIndex + 1) % images.length]}
        //                 prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        //                 onCloseRequest={() => this.setState({ isOpen: false })}
        //                 onMovePrevRequest={() =>
        //                     this.setState({
        //                         photoIndex: (photoIndex + images.length - 1) % images.length,
        //                     })
        //                 }
        //                 onMoveNextRequest={() =>
        //                     this.setState({
        //                         photoIndex: (photoIndex + 1) % images.length,
        //                     })
        //                 }
        //             />
        //         )}
        //         <h2 className="news-item-header">
        //             {icon && <Icon icon={icon} size={'big'}/>}
        //             {title}
        //         </h2>
        //         <div className="news-item-content">
        //             {imgSrc && <img className="pointer" src={imgSrc} alt={""} onClick={() => this.setState({isOpen: true})}/>}
        //             {content}
        //         </div>
        //         <div className="clear"/>
        //
        //         {onBackClick && <div onClick={onBackClick} className="pointer">{"<-"} Tagasi</div>}
        //         <ImageGallery
        //             items={this.images1}
        //         />
        //     </div>
        // );
    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.state.slideInterval !== prevState.slideInterval ||
        //     this.state.slideDuration !== prevState.slideDuration) {
        //     // refresh setInterval
        //     this._imageGallery.pause();
        //     this._imageGallery.play();
        // }
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
}