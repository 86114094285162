import React, {Component} from 'react';
import NotFound from "./NotFound";
import NewsDetail from "../components/post/NewsDetail";
import RouterStore from "../stores/RouterStore";
import {inject} from "mobx-react";
import WPService from "../services/WPService";

interface INewsProps {
    match: any,
    routing?: RouterStore,
    wpService?: WPService
}

export const newsListDetail = [
    {
        id: 1,
        title: 'Kaunist eesti viipekeele päeva',
        icon: 'post',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu ultrices vitae auctor eu augue ut lectus. Mauris nunc congue nisi vitae suscipit. Pharetra diam sit amet nisl. Lorem mollis aliquam ut porttitor leo a. Donec massa sapien faucibus et molestie ac. Etiam erat velit scelerisque in dictum non. Aliquet bibendum enim facilisis gravida neque convallis a. Cursus eget nunc scelerisque viverra mauris in aliquam sem fringilla. Velit dignissim sodales ut eu sem.\n' +
            '\n' +
            'Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Tempus urna et pharetra pharetra massa massa. Enim facilisis gravida neque convallis a cras. Sed lectus vestibulum mattis ullamcorper velit sed. Mattis vulputate enim nulla aliquet porttitor. Mi quis hendrerit dolor magna eget. Risus nec feugiat in fermentum posuere urna nec tincidunt. Quis vel eros donec ac odio tempor. Morbi blandit cursus risus at ultrices mi tempus. Leo vel fringilla est ullamcorper eget nulla. Tellus at urna condimentum mattis. Amet consectetur adipiscing elit duis tristique sollicitudin. Blandit turpis cursus in hac habitasse platea dictumst quisque. Imperdiet nulla malesuada pellentesque elit. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam. Ac tortor vitae purus faucibus. Rhoncus est pellentesque elit ullamcorper. Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Pellentesque adipiscing commodo elit at imperdiet dui accumsan.\n' +
            '\n' +
            'Sodales ut eu sem integer vitae justo eget magna fermentum. Pellentesque pulvinar pellentesque habitant morbi. Et tortor consequat id porta. Purus in mollis nunc sed id. Praesent semper feugiat nibh sed. Massa tempor nec feugiat nisl pretium fusce id velit ut. Ornare aenean euismod elementum nisi quis eleifend quam. Gravida rutrum quisque non tellus orci ac auctor. Mattis molestie a iaculis at erat pellentesque adipiscing commodo elit. Id porta nibh venenatis cras. A iaculis at erat pellentesque adipiscing. Amet purus gravida quis blandit turpis cursus. Varius morbi enim nunc faucibus. Mauris nunc congue nisi vitae suscipit tellus mauris a diam. Eget felis eget nunc lobortis mattis aliquam faucibus purus. Tristique magna sit amet purus gravida. Id cursus metus aliquam eleifend mi in nulla posuere sollicitudin. Ac auctor augue mauris augue neque gravida. Turpis egestas pretium aenean pharetra magna ac placerat. Urna et pharetra pharetra massa massa ultricies mi quis..',
        imgSrc: 'https://www.thewowstyle.com/wp-content/uploads/2015/07/3d-only-natural-backgrounds-wallpapers.jpg'
    },
    {
        id: 2,
        title: 'Kaunist eesti viipekeele päeva',
        icon: 'notice',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu ultrices vitae auctor eu augue ut lectus. Mauris nunc congue nisi vitae suscipit. Pharetra diam sit amet nisl. Lorem mollis aliquam ut porttitor leo a. Donec massa sapien faucibus et molestie ac. Etiam erat velit scelerisque in dictum non. Aliquet bibendum enim facilisis gravida neque convallis a. Cursus eget nunc scelerisque viverra mauris in aliquam sem fringilla. Velit dignissim sodales ut eu sem.\n' +
            '\n' +
            'Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Tempus urna et pharetra pharetra massa massa. Enim facilisis gravida neque convallis a cras. Sed lectus vestibulum mattis ullamcorper velit sed. Mattis vulputate enim nulla aliquet porttitor. Mi quis hendrerit dolor magna eget. Risus nec feugiat in fermentum posuere urna nec tincidunt. Quis vel eros donec ac odio tempor. Morbi blandit cursus risus at ultrices mi tempus. Leo vel fringilla est ullamcorper eget nulla. Tellus at urna condimentum mattis. Amet consectetur adipiscing elit duis tristique sollicitudin. Blandit turpis cursus in hac habitasse platea dictumst quisque. Imperdiet nulla malesuada pellentesque elit. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam. Ac tortor vitae purus faucibus. Rhoncus est pellentesque elit ullamcorper. Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Pellentesque adipiscing commodo elit at imperdiet dui accumsan.\n' +
            '\n' +
            'Sodales ut eu sem integer vitae justo eget magna fermentum. Pellentesque pulvinar pellentesque habitant morbi. Et tortor consequat id porta. Purus in mollis nunc sed id. Praesent semper feugiat nibh sed. Massa tempor nec feugiat nisl pretium fusce id velit ut. Ornare aenean euismod elementum nisi quis eleifend quam. Gravida rutrum quisque non tellus orci ac auctor. Mattis molestie a iaculis at erat pellentesque adipiscing commodo elit. Id porta nibh venenatis cras. A iaculis at erat pellentesque adipiscing. Amet purus gravida quis blandit turpis cursus. Varius morbi enim nunc faucibus. Mauris nunc congue nisi vitae suscipit tellus mauris a diam. Eget felis eget nunc lobortis mattis aliquam faucibus purus. Tristique magna sit amet purus gravida. Id cursus metus aliquam eleifend mi in nulla posuere sollicitudin. Ac auctor augue mauris augue neque gravida. Turpis egestas pretium aenean pharetra magna ac placerat. Urna et pharetra pharetra massa massa ultricies mi quis.',
        imgSrc: 'https://www.thewowstyle.com/wp-content/uploads/2015/07/beautiful-natural-lakes-26-photos-02.jpg'
    },
    {
        id: 5,
        title: 'Kaunist eesti viipekeele päeva',
        icon: 'post',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu ultrices vitae auctor eu augue ut lectus. Mauris nunc congue nisi vitae suscipit. Pharetra diam sit amet nisl. Lorem mollis aliquam ut porttitor leo a. Donec massa sapien faucibus et molestie ac. Etiam erat velit scelerisque in dictum non. Aliquet bibendum enim facilisis gravida neque convallis a. Cursus eget nunc scelerisque viverra mauris in aliquam sem fringilla. Velit dignissim sodales ut eu sem.\n' +
            '\n' +
            'Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Tempus urna et pharetra pharetra massa massa. Enim facilisis gravida neque convallis a cras. Sed lectus vestibulum mattis ullamcorper velit sed. Mattis vulputate enim nulla aliquet porttitor. Mi quis hendrerit dolor magna eget. Risus nec feugiat in fermentum posuere urna nec tincidunt. Quis vel eros donec ac odio tempor. Morbi blandit cursus risus at ultrices mi tempus. Leo vel fringilla est ullamcorper eget nulla. Tellus at urna condimentum mattis. Amet consectetur adipiscing elit duis tristique sollicitudin. Blandit turpis cursus in hac habitasse platea dictumst quisque. Imperdiet nulla malesuada pellentesque elit. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam. Ac tortor vitae purus faucibus. Rhoncus est pellentesque elit ullamcorper. Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Pellentesque adipiscing commodo elit at imperdiet dui accumsan.\n' +
            '\n' +
            'Sodales ut eu sem integer vitae justo eget magna fermentum. Pellentesque pulvinar pellentesque habitant morbi. Et tortor consequat id porta. Purus in mollis nunc sed id. Praesent semper feugiat nibh sed. Massa tempor nec feugiat nisl pretium fusce id velit ut. Ornare aenean euismod elementum nisi quis eleifend quam. Gravida rutrum quisque non tellus orci ac auctor. Mattis molestie a iaculis at erat pellentesque adipiscing commodo elit. Id porta nibh venenatis cras. A iaculis at erat pellentesque adipiscing. Amet purus gravida quis blandit turpis cursus. Varius morbi enim nunc faucibus. Mauris nunc congue nisi vitae suscipit tellus mauris a diam. Eget felis eget nunc lobortis mattis aliquam faucibus purus. Tristique magna sit amet purus gravida. Id cursus metus aliquam eleifend mi in nulla posuere sollicitudin. Ac auctor augue mauris augue neque gravida. Turpis egestas pretium aenean pharetra magna ac placerat. Urna et pharetra pharetra massa massa ultricies mi quis.',
        imgSrc: 'https://www.thewowstyle.com/wp-content/uploads/2015/07/beautiful-natural-lakes-26-photos-04.jpg'
    },
    {
        id: 4,
        title: 'Kaunist eesti viipekeele päeva',
        icon: 'post',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu ultrices vitae auctor eu augue ut lectus. Mauris nunc congue nisi vitae suscipit. Pharetra diam sit amet nisl. Lorem mollis aliquam ut porttitor leo a. Donec massa sapien faucibus et molestie ac. Etiam erat velit scelerisque in dictum non. Aliquet bibendum enim facilisis gravida neque convallis a. Cursus eget nunc scelerisque viverra mauris in aliquam sem fringilla. Velit dignissim sodales ut eu sem.\n' +
            '\n' +
            'Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Tempus urna et pharetra pharetra massa massa. Enim facilisis gravida neque convallis a cras. Sed lectus vestibulum mattis ullamcorper velit sed. Mattis vulputate enim nulla aliquet porttitor. Mi quis hendrerit dolor magna eget. Risus nec feugiat in fermentum posuere urna nec tincidunt. Quis vel eros donec ac odio tempor. Morbi blandit cursus risus at ultrices mi tempus. Leo vel fringilla est ullamcorper eget nulla. Tellus at urna condimentum mattis. Amet consectetur adipiscing elit duis tristique sollicitudin. Blandit turpis cursus in hac habitasse platea dictumst quisque. Imperdiet nulla malesuada pellentesque elit. Vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam. Ac tortor vitae purus faucibus. Rhoncus est pellentesque elit ullamcorper. Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Pellentesque adipiscing commodo elit at imperdiet dui accumsan.\n' +
            '\n' +
            'Sodales ut eu sem integer vitae justo eget magna fermentum. Pellentesque pulvinar pellentesque habitant morbi. Et tortor consequat id porta. Purus in mollis nunc sed id. Praesent semper feugiat nibh sed. Massa tempor nec feugiat nisl pretium fusce id velit ut. Ornare aenean euismod elementum nisi quis eleifend quam. Gravida rutrum quisque non tellus orci ac auctor. Mattis molestie a iaculis at erat pellentesque adipiscing commodo elit. Id porta nibh venenatis cras. A iaculis at erat pellentesque adipiscing. Amet purus gravida quis blandit turpis cursus. Varius morbi enim nunc faucibus. Mauris nunc congue nisi vitae suscipit tellus mauris a diam. Eget felis eget nunc lobortis mattis aliquam faucibus purus. Tristique magna sit amet purus gravida. Id cursus metus aliquam eleifend mi in nulla posuere sollicitudin. Ac auctor augue mauris augue neque gravida. Turpis egestas pretium aenean pharetra magna ac placerat. Urna et pharetra pharetra massa massa ultricies mi quis.',
        imgSrc: 'https://www.thewowstyle.com/wp-content/uploads/2015/07/Forests-bridges-natural-scenery-hd-wallpaper-backgrounds-.jpg'
    }
];

@inject('routing')
@inject('wpService')
export default class News extends Component<INewsProps, {}> {
    private newsData: any;

    constructor(props) {
        super(props);
        const newsId = parseInt(props.match.params.id);
        // @ts-ignore
        this.newsData = newsListDetail.filter(i => i.id === newsId).first;
        this.goToIndex = this.goToIndex.bind(this);
    }


    render() {
        if(this.newsData) {
            return (
                <NewsDetail {...this.newsData} onBackClick={this.goToIndex}/>
            );
        }
        return (
            <NotFound/>
        );
    }

    goToIndex() {
        this.props.routing.goBack();
    }
}