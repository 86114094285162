import React from "react";
import {RouterStore as MobxRouterStore} from 'mobx-react-router';
import {parse} from "query-string";

interface IRouterStore {
    navigate(event : React.SyntheticEvent<HTMLElement>);
    getQuery() : object;
    getHash(): string
}

export default class RouterStore extends MobxRouterStore implements IRouterStore
{
    constructor() {
        super();
    }

    navigate(event : React.SyntheticEvent<HTMLElement>) {
        event.preventDefault();
        const url = event.currentTarget.attributes.getNamedItem("href").value;
        if(url != '#') {
            this.push(url);
        }
    }

    isCurrentPath(path : string) : boolean {
        return this.history.location.pathname == path;
    }

    getQuery() : object {
        return parse(this.history.location.search);
    }

    getHash(): string {
        let hash = this.history.location.hash;
        if(hash.length > 0) {
            return hash.substr(1);
        }
        return '';
    }

    setHash(hash) {
        this.history.replace(this.location.pathname + this.location.search + '#' + hash)
    }

    getUrl() {
        return this.location.pathname + this.location.search + this.location.hash;
    }
}
