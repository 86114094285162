import BaseService from "./BaseService";
import {stringify} from "query-string";

export default class WPService extends BaseService {
    getPosts(page = 0, pageSize = 10, categories?: Array<number>, slug?: string, before?: string, after?: string) {
        let query: any = {
            page,
            per_page: pageSize,
            _embed: ''
        };
        if (categories) {
            query.categories = categories.map(id => String(id)).join(',')
        }
        if (slug) {
            query.slug = slug;
        }
        if (before) {
            query.before = before;
        }
        if (after) {
            query.after = after;
        }
        return this.client.get('/wp-json/wp/v2/posts?' + stringify(query))
    }

    getVideos(page = 1, pageSize = 10, categories?: Array<number>, slug?: string, before?: string, after?: string) {
        let query: any = {
            page,
            per_page: pageSize,
            _embed: ''
        };
        if (categories) {
            query.categories = categories.map(id => String(id)).join(',')
        }
        if (slug) {
            query.slug = slug;
        }
        if (before) {
            query.before = before;
        }
        if (after) {
            query.after = after;
        }
        return this.client.get('/wp-json/wp/v2/aiovg_videos?' + stringify(query))
    }

    getGalleries(page = 1, pageSize = 10, categories?: Array<number>, slug?: string, before?: string, after?: string) {
        let query: any = {
            page,
            per_page: pageSize,
            _embed: ''
        };
        if (categories) {
            query.categories = categories.map(id => String(id)).join(',')
        }
        if (slug) {
            query.slug = slug;
        }
        if (before) {
            query.before = before;
        }
        if (after) {
            query.after = after;
        }
        return this.client.get('/wp-json/wp/v2/gallery?' + stringify(query))
    }

    getPages(page = 0, pageSize = 10, slug?: string) {
        let query: any = {
            page,
            per_page: pageSize,
            _embed: ''
        };
        if (slug) {
            query.slug = slug;
        }
        return this.client.get('/wp-json/wp/v2/pages?' + stringify(query))
    }

    getCategories() {
        return this.client.get('/wp-json/wp/v2/categories')
    }

    getEventCategories() {
        return this.client.get('/wp-json/wp/v2/mec_category')
    }

    getWeatherStatus() {
        return this.client.get('/wp-json/wp/v2/weather-status')
    }

    getEvents(page, pageSize, categories?: Array<number>, fields?: Array<string>, from_date?, to_date?) {
        let query: any = {
            page,
            per_page: pageSize
        };
        let filters = [];
        if (from_date) {
            filters.push({
                value: from_date,
                compare: '>=',
                key: 'mec_end_date'
            })
        }
        if (to_date) {
            filters.push({
                value: to_date,
                compare: '<=',
                key: 'mec_end_date'
            })
        }
        filters.forEach((filterData, index) => {
            query[`filter[meta_query][${index}][key]`] = filterData.key;
            query[`filter[meta_query][${index}][value]`] = filterData.value;
            query[`filter[meta_query][${index}][compare]`] = filterData.compare;
        });
        query['filter[orderby]'] = 'mec_start_date'
        query['order'] = 'desc'

        if (fields) {
            query['_fields'] = fields.join(',');
        }
        if (categories) {
            query['mec_category'] = categories.map(id => String(id)).join(',');
        }
        return this.client.get('/wp-json/wp/v2/mec-events?' + stringify(query))
    }

    getMedia(mediaId: number) {
        return this.client.get(`/wp-json/wp/v2/media/${mediaId}`);
    }

}
