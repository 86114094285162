import {Component} from "react";
import * as React from "react";

interface IIconProps {
    icon: string,
    size?: string
}

export default class Icon extends Component<IIconProps, {}> {
    render() {
        let classNames =  ['icon'];
        if(this.props.size) {
            classNames.push(this.props.size);
        }
        classNames.push(`icon-${this.props.icon}`);
        return (
            <i className={classNames.join(' ')}/>
        );
    }
}