import axios, {AxiosRequestConfig} from 'axios'
import AxiosResultPromise from "./AxiosResultPromise";

class ApiClient {

    protected config : AxiosRequestConfig;
    protected errorHandler : (reason: any) => void;

    constructor (baseUrl: string, errorHandler? : (reason: any) => void) {
        this.config = {
            baseURL: baseUrl,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFTOKEN'
        };
        this.errorHandler = errorHandler;
    }

    public setAuthorization(token? : string) {
        if(this.config.headers == null) {
            this.config.headers = {};
        }
        if(token == null && this.config.headers.hasOwnProperty('Authorization')) {
            delete this.config.headers.Authorization;
        }
        else {
            this.config.headers.Authorization = token;
        }
    }

    private onError(reason: any) {
        if(this.errorHandler != null) {
            this.errorHandler(reason);
        }
    }

    public get(endpoint : string) {
        return axios.get(endpoint, this.config);
    }

    public post(endpoint : string, data: object) {
        return axios.post(endpoint, data, this.config);
    }

    public put(endpoint : string, data: object) {
        return axios.put(endpoint, data, this.config);
    }

    public delete(endpoint : string) {
        return axios.delete(endpoint, this.config);
    }
}
export default ApiClient;