import {Component} from "react";
import * as React from "react";
import Icon from "../icon";
import MenuItem from "../menu/MenuItem";
import Menu from "../menu";
import WPService from "../../services/WPService";
import {inject} from "mobx-react";
import * as moment from "moment";
import { Roller } from "react-awesome-spinners";

interface IGalleryListProps {
    year: number;
    wpService?: WPService
    showDate?: boolean;
    onClickGallery? (gallery): void;
    showThreeImageInRow?: boolean;
}

interface IGalleryListState {
    postAfter: string;
    postBefore: string;
    data: any[];
    page: number;
    pageSize: number;
    postHasMore: boolean;

}

@inject('wpService')
export default class GalleryList extends Component<IGalleryListProps, IGalleryListState> {
    constructor(props) {
        super(props);
        this.state = {
            postAfter: `${this.props.year - 1}-12-31T23:59:59`,
            postBefore: `${this.props.year + 1}-01-01T00:00:00`,
            data: null,
            page: 1,
            pageSize : 100,
            postHasMore: false
        }
        this.loadGalleries();
    }

    componentDidUpdate(prevProps: Readonly<IGalleryListProps>, prevState: Readonly<IGalleryListState>, snapshot?: any) {
        if(prevProps.year !== this.props.year) {
            const state = {
                postAfter: `${this.props.year - 1}-12-31T23:59:59`,
                postBefore: `${this.props.year + 1}-01-01T00:00:00`,
                data: null
            };
            this.setState(state);
            this.loadGalleries(1, null, state.postAfter, state.postBefore);
        }
    }

    loadGalleries(page = null, pageSize=null, postAfter=null, postBefore=null, append=false) {
        if(!page) {
            page = this.state.page;
        }
        if(!pageSize) {
            pageSize = this.state.pageSize
        }
        if(!postAfter) {
            postAfter = this.state.postAfter
        }
        if(!postBefore) {
            postBefore = this.state.postBefore
        }
        this.props.wpService.getGalleries(page, pageSize, null, null, postBefore, postAfter).then(response => {
            const data = append ? [...this.state.data, ...response.data] : response.data;
            response.data.forEach(gallery => {
               if(gallery.featured_media) {
                   this.props.wpService.getMedia(gallery.featured_media).then(response2 => {
                       gallery.thumbnail_url = response2.data.media_details.sizes.thumbnail.source_url;
                       this.setState({data: [...this.state.data]});
                   });
               }
               else {
               }
            });
            this.setState({
                data,
                page,
                pageSize,
                postBefore,
                postAfter,
                postHasMore: data.length >= pageSize * page
            })
        })
    }


    render() {
        if(this.state.data === null) {
            return <Roller/>;
        }
        return (
            <div className="gallery-list">
                {this.state.data.map((gallery, galleryIndex) => (
                    <div key={galleryIndex} className={"gallery-item"} onClick={this.itemOnClick.bind(this, gallery)}>
                        {gallery.thumbnail_url && <img src={gallery.thumbnail_url} alt={""}/>}
                        {this.props.showThreeImageInRow && gallery['gallery-images'][0] && <img src={gallery['gallery-images'][0].thumbnail_url} alt={""}/>}
                        {this.props.showThreeImageInRow && gallery['gallery-images'][1] && <img src={gallery['gallery-images'][1].thumbnail_url} alt={""}/>}
                        <div>
                            <div className="img-title" dangerouslySetInnerHTML={{__html: gallery.title.rendered}}/>
                            <div className={"postmeta"}>
                                {this.props.showDate && <span>{moment(gallery.date).format('DD.MM.YYYY')}</span>}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }


    itemOnClick(gallery) {
        if(this.props.onClickGallery) {
            this.props.onClickGallery(gallery);
        }
    }
}
