import React, {Component} from 'react';
import PostItem from "../components/post/PostItem";
import RouterStore from "../stores/RouterStore";
import {inject} from "mobx-react";
import MenuItem from "../components/menu/MenuItem";
import WPService from "../services/WPService";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactDOM from 'react-dom';
import { Roller } from "react-awesome-spinners";

interface IIndexState {
    filterCatId: string;
    scrollableTarget?: any,
    posts: Array<any>,
    page: number,
    pageSize: number,
    postFilterIndex: number,
    postHasMore: boolean,
}

interface IIndexProps {
    routing?: RouterStore,
    wpService?: WPService,
    categories?: any,
}

@inject('routing')
@inject('wpService')
@inject('categories')
export default class Index extends Component<IIndexProps, IIndexState> {
    postFilters = [
        {
            label: 'Kõik',
            icon: 'folder-with-file',
            catId: '-1',
            slug: 'uudised,info,varia'
        },
        {
            label: 'Uudised',
            icon: 'list-is-empty',
            catId: '1',
            slug: 'uudised'
        },
        {
            label: 'Info',
            icon: 'bell',
            catId: '2',
            slug: 'info'
        },
        {
            label: 'Varia',
            icon: 'counter',
            catId: '3',
            slug: 'varia'
        }
    ];

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = {
            filterCatId: '-1',
            postFilterIndex: 0,
            posts: [],
            page: 1,
            pageSize: 10,
            postHasMore: true,
        }
        this.postFilters.map(item => {
            item.catId = item.slug.split(',').map(slug => {
                const category = this.props.categories.filter(category => category.slug === slug).first;
                return category ? String(category.id) : null;
            }).filter(slug => slug).join(',')
        });
        this.loadPosts();
    }

    loadPosts(page = null,pageSize=null, postFilterIndex=null, append=false) {
        if(!page) {
            page = this.state.page;
        }
        if(!pageSize) {
            pageSize = this.state.pageSize
        }
        if(postFilterIndex === null) {
            postFilterIndex = this.state.postFilterIndex;
        }

        const categoryIds = this.postFilters[postFilterIndex].catId.split(',').map(id => parseInt(id)).filter(id => Number.isInteger(id));
        this.props.wpService.getPosts(page, pageSize, categoryIds).then(response => {
            const posts = append ? [...this.state.posts, ...response.data] : response.data;
            this.setState({
                posts,
                page,
                pageSize,
                postHasMore: posts.length >= pageSize * page
            })
        })
    }

    fetchData() {
        this.loadPosts(this.state.page + 1, null, null, true);
    }

    componentDidMount() {
        this.setState({scrollableTarget: ReactDOM.findDOMNode(this).parentElement});
    }

    render() {
        return (
            <div>
                <div className="news-filter-list">
                    {this.postFilters.map((postFilter, postFilterIndex) => <MenuItem key={postFilterIndex}
                                                                                     color={postFilterIndex === this.state.postFilterIndex ? 'green' : 'blue'}
                                                                                     label={postFilter.label}
                                                                                     border={true}
                                                                                     icon={postFilter.icon}
                                                                                     iconPosition={'right'}
                                                                                     onClick={postFilterIndex === this.state.postFilterIndex ? undefined : this.selectPostFilter.bind(this, postFilterIndex)}/>)}
                </div>
                {this.state.scrollableTarget && <InfiniteScroll
                    dataLength={this.state.posts.length} //This is important field to render the next data
                    next={this.fetchData}
                    hasMore={this.state.postHasMore}
                    loader={<div className={"center"}><Roller/></div>}
                    scrollableTarget={this.state.scrollableTarget}
                    style={{overflow: "hidden"}}
                    // endMessage={
                    //     <p style={{textAlign: 'center'}}>
                    //         <b>Yay! You have seen it all</b>
                    //     </p>
                    // }
                    // below props only if you need pull down functionality
                    //refreshFunction={this.refresh}
                    // pullDownToRefresh
                    // pullDownToRefreshContent={
                    //     <h3 style={{textAlign: 'center'}}>&#8595; Pull down to refresh</h3>
                    // }
                    // releaseToRefreshContent={
                    //     <h3 style={{textAlign: 'center'}}>&#8593; Release to refresh</h3>
                    // }
                >
                    {this.state.posts.map((post, postIndex) => <PostItem key={postIndex} {...post} categories={this.props.categories.filter(cat => post.categories.indexOf(cat.id) !== -1)}
                                                                                onClick={this.selectPost.bind(this, postIndex)}/>)}
                </InfiniteScroll>}
            </div>
        );
    }



    selectPost(index) {
        const postData = this.state.posts[index];
        const url = new URL(postData.link);
        this.props.routing.push(url.pathname);
    }

    selectPostFilter(index) {
        if(this.state.postFilterIndex === index) {
            return;
        }
        this.setState({postFilterIndex: index, posts: []});
        this.loadPosts(1, null, index);
    }
}
