import {Component} from "react";
import * as React from "react";
import Icon from "../icon";
import {NavLink} from "react-router-dom";

interface IMenuItemProps {
    color?: string,
    border?: boolean,
    label?: string,
    icon?: string,
    iconSize?: string,
    iconPosition?: string,
    row?: boolean
    link?: string
    width?: string
    subMenu?: boolean
    onClick?: (e: any) => void;
    forceLink?: boolean;
    className?: string;
}

export default class MenuItem extends Component<IMenuItemProps, {}>{
    render() {
        let classNames = ['menu-item'];
        let style: any = {};
        const {forceLink, color, border, label, icon, row, link, iconSize, iconPosition, width, subMenu, onClick, className} = this.props;
        if(border) {
            classNames.push('menu-item-border');
        }
        if(color) {
            classNames.push(color);
        }
        if(row) {
            classNames.push("menu-item-row");
        }
        if(iconPosition) {
            classNames.push(`menu-icon-${iconPosition}`);
        }
        if(width) {
            style.width = width;
        }
        if(onClick) {
            classNames.push('pointer')
        }
        if(className) {
            classNames.push(className);
        }
        return (
            <div style={style} className={classNames.join(" ")} onClick={onClick}>
                {link && <NavLink to={link}>
                    {label && <div className="menu-item-label">{label}</div>}
                    {icon && <Icon icon={icon} size={iconSize}/>}
                    {forceLink && this.props.children}
                </NavLink>}
                {!link && label && <div className="menu-item-label">{label}</div>}
                {!link &&icon && <Icon icon={icon} size={iconSize}/>}
                {!forceLink && this.props.children && (subMenu ? <div className="menu-item-sub">{this.props.children}</div> : this.props.children)}
            </div>
        );
    }

}
