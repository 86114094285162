import React, {Component} from 'react';
import GalleryList from "../components/gallery/GalleryList";
import {inject} from "mobx-react";
import RouterStore from "../stores/RouterStore";
import MenuItem from "../components/menu/MenuItem";
import Menu from "../components/menu";
import VideoList from "../components/video/VideoList";
import WPService from "../services/WPService";
import NotFound from "./NotFound";
import HtmlContent from "../components/html-content";

interface IMediaProps {
    match?: any;
    routing?: RouterStore,
    wpService?: WPService,
}

interface IMediaState {
    year: number;
    postAfter: string;
    postBefore: string;
    isGallery: boolean;
    loading: boolean;
    data?: any;
}

@inject('routing')
@inject('wpService')
export default class Media extends Component<IMediaProps, IMediaState> {

    constructor(props) {
        super(props);
        const year = parseInt(this.props.match.params.year);
        this.state = {
            year: parseInt(this.props.match.params.year),
            postAfter: `${year - 1}-12-31T23:59:59`,
            postBefore: `${year + 1}-01-01T00:00:00`,
            isGallery: this.props.match.params.type === 'pildigalerii',
            loading: true
        }
        this.onSelectYear = this.onSelectYear.bind(this);
        this.goToGalleryListPage = this.goToGalleryListPage.bind(this);
        this.onSelectedGallery = this.onSelectedGallery.bind(this);
        this.goToVideoListPage = this.goToVideoListPage.bind(this);
        this.onSelectedVideo = this.onSelectedVideo.bind(this);
        //this.loadPage();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            year: parseInt(nextProps.match.params.year),
        }
    }

    componentDidUpdate(prevProps: Readonly<IMediaProps>, prevState: Readonly<IMediaState>, snapshot?: any) {
        if(prevState.year !== this.state.year || prevState.isGallery !== this.state.isGallery) {
            this.loadPage();
        }
    }

    loadPage(year?: number, isGallery?: boolean) {
        if(!year) {
            year = this.state.year;
        }
        if(isGallery === undefined) {
            isGallery = this.state.isGallery;
        }
        this.props.wpService.getPages(1, 1, `meedia-${isGallery ? 'pildigalerii' : 'videod'}-${year}`).then(response => {
            this.setState({data: response.data.length === 0 ? null : response.data[0]});
        });
    }


    render() {
        return (
            <div>
                <h1 className="center">Meedia</h1>
                <Menu className={'item-inline-block'} center={true}>
                    {[2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006].map(year => (
                        <MenuItem key={year} label={String(year)} border={true} color={this.state.year === year ? 'green' : 'blue'} onClick={this.onSelectYear.bind(this, year)}/>)
                    )}
                </Menu>
                <div style={{height: "10px"}}/>
                <div className="gallery-block pointer" onClick={this.goToGalleryListPage}>
                    <p>Pildigalerii {this.state.year}</p>
                    <GalleryList year={this.state.year} onClickGallery={this.onSelectedGallery}/>
                    <div className="right-button">
                        <MenuItem icon={'double-tap'} iconPosition={'right'} color={'blue'} label={"Vaata edasi"} border={true} onClick={this.goToGalleryListPage} />
                    </div>
                    <div className="clear"/>
                </div>
                <div className="video-list-block mt-3 pointer" onClick={this.goToVideoListPage}>
                    <p>Video {this.state.year}</p>
                    <VideoList year={this.state.year} onClickVideo={this.onSelectedVideo}/>
                    <div className="right-button">
                        <MenuItem icon={'double-tap'} iconPosition={'right'} color={'blue'} label={"Vaata edasi"} border={true} onClick={this.goToVideoListPage} />
                    </div>
                    <div className="clear"/>
                </div>
            </div>
        )
    }

    onSelectedVideo(video) {
        this.goToVideoListPage();
    }

    onSelectedGallery(gallery) {
        this.goToGalleryListPage();
        //this.props.routing.push(`/galleries/${gallery.slug}`);
    }

    goToGalleryListPage() {
        this.props.routing.push(`/meedia-pildigalerii-${this.state.year}`);
    }

    goToVideoListPage() {
        this.props.routing.push(`/meedia-video-${this.state.year}`);
    }

    onSelectYear(year) {
        this.props.routing.push(`/meedia-${year}`);
    }
}
