import {Component} from "react";
import * as React from "react";
import {Button} from "react-bootstrap";
import Icon from "../icon";
import {Link} from "react-router-dom";
import MenuLeft from "../sidebars/MenuLeft";
import MenuRight from "../sidebars/MenuRight";
import {inject} from "mobx-react";
import RouterStore from "../../stores/RouterStore";

interface HeaderTopProps {
    routing?: RouterStore;
}

interface HeaderTopState {
    openedMenu: boolean;
    currentLocation?: string;
}

@inject('routing')
export default class HeaderTop extends Component<HeaderTopProps, HeaderTopState>{

    private timeout: any;

    constructor(props) {
        super(props);
        this.menuOnClick = this.menuOnClick.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.state = {
            openedMenu: false
        }
    }

    componentDidMount() {
        window.addEventListener('locationchange', e => console.log(e));

    }

    componentDidUpdate(prevProps: Readonly<HeaderTopProps>, prevState: Readonly<HeaderTopState>, snapshot?: any) {
        if(prevState.currentLocation !== this.state.currentLocation) {
            this.setState({openedMenu: false});
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {currentLocation: window.location.href};
    }

    render() {
        return (
            <div className="header top">
                {this.state.openedMenu && <div className="menu-content">
                    <MenuLeft />
                    <MenuRight iconPosition="right"/>
                </div>}
                <Button className="menu-button" onClick={this.menuOnClick}>
                    <Icon icon={this.state.openedMenu ? 'close' : 'menu'}/>
                </Button>
                <Link to="/">
                    <div className="logo-img"/>
                </Link>
            </div>
        );
    }

    menuOnClick() {
        const openedMenu = !this.state.openedMenu;
        this.setState({openedMenu});
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
        if(openedMenu) {
            setTimeout(this.closeMenu, 10000);
        }
    }

    closeMenu() {
        this.setState({openedMenu: false});
    }
}
