import React, {Component} from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import Icon from "../components/icon";

export default class Timeline extends Component {
    render() {
        return (
            <div className="history-timeline">
                <h1>Ajajoon</h1>
                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="6. aprill 1931"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"estonia-coatofarms"}/>}
                    >
                        <h3 className="vertical-timeline-element-title">Tartu Kurttummade Selts</h3>
                        <p>
                            Asutaja nimetus
                        </p>
                        <div className="center">
                            <img src="https://www.tarky.ee/wp-content/uploads/2021/01/IMG_8635.jpg" alt=""/>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="1931-1932"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"estonia-coatofarms"}/>}
                    >
                        <h3 className="vertical-timeline-element-title">Karl Siitas</h3>
                        <p>
                            Esimene esimees
                        </p>
                        <div className="center">
                            <img src="https://www.tarky.ee/wp-content/uploads/2022/02/Karl-Siitas-–-ajajoon.jpg" alt=""/>
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="August 1934"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"estonia-coatofarms"}/>}
                    >
                        <h3 className="vertical-timeline-element-title">Lõuna-Eesti Kurttummade Päev</h3>
                        <p>
                            Esimene kutsuti kokku
                        </p>
                        <div className="center">
                            <img src="https://www.tarky.ee/wp-content/uploads/2021/01/1934.jpg" alt=""/>
                        </div>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="27. juuni 1940"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"hammer-sickle-red"}/>}
                        contentStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
                    >
                        <h3 className="vertical-timeline-element-title">
                            Üleriigiline seltsi või ühingu sulgemine ja tegevuse keeld.
                        </h3>

                        <div className="center">
                            <img src="" alt=""/>
                        </div>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="1941"
                        iconStyle={{ background: 'rgb(255, 255, 255)', color: '#fff' }}
                        icon={<Icon icon={"nazy"}/>}
                        contentStyle={{ background: 'rgb(0, 0, 0)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(0, 0, 0)' }}
                    >
                        <h3 className="vertical-timeline-element-title">Kurttummade Seltsi suletud</h3>
                        <p>
                            Okupatsioonivõimud riigis igasuguse ühistegevuse keelatud
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="1944"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"hammer-sickle-red"}/>}
                        contentStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
                    >
                        <h3 className="vertical-timeline-element-title">Taasalustas kokkusaamine</h3>

                        <div className="center">
                            <img src="" alt=""/>
                        </div>
                    </VerticalTimelineElement>


                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="11. veebruar 1945"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"hammer-sickle-red"}/>}
                        contentStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
                    >
                        <h3 className="vertical-timeline-element-title">Nõukogude juhatuses</h3>
                        <p>
                            Esimene koosolek kokkusaamine Päeva tn. 7 majas
                        </p>
                        <div className="center">
                            <img src="https://www.tarky.ee/wp-content/uploads/2021/01/Paeva-7.jpg" alt=""/>
                        </div>
                    </VerticalTimelineElement>



                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="29. juuni 1945"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"hammer-sickle-red"}/>}
                        contentStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
                    >
                        <h3 className="vertical-timeline-element-title">Remont klubis</h3>
                        <p>
                            Kalevi tn. 29 teisel korrusel ruumid vajasid remonti, katuses oli suur auk
                        </p>
                        <div className="center">
                            <img src="https://www.tarky.ee/wp-content/uploads/2021/01/Kalevi-29.jpg" alt=""/>
                        </div>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="20. juuni 1947"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"hammer-sickle-red"}/>}
                        contentStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
                    >
                        <h3 className="vertical-timeline-element-title">Õppe-tootmistöökoja</h3>
                        <p>
                            Avati Tammemõisas Suur-Kaar 36
                        </p>
                        <div className="center">
                            <img src="https://www.tarky.ee/wp-content/uploads/2021/01/Suur-Kaar-36.jpg" alt=""/>
                        </div>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="18. juuli 1947"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"hammer-sickle-red"}/>}
                        contentStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
                    >
                        <h3 className="vertical-timeline-element-title">Kurtide Keskuse Tartu osakonna spordiselts</h3>
                        <p>
                            Hakati väike klubiruum, kus toimusid kultuurilised üritused, aastapäevade tähistamise peod, nääri- ja lastepeod
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="1955"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"hammer-sickle-red"}/>}
                        contentStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
                    >
                        <h3 className="vertical-timeline-element-title">Punanurk</h3>
                        <p>
                            Hakati väike klubiruum, kus toimusid kultuurilised üritused, aastapäevade tähistamise peod, nääri- ja lastepeod
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="28. jaanuar 1962"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"hammer-sickle-red"}/>}
                        contentStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
                    >
                        <h3 className="vertical-timeline-element-title">Kurttummade Ühingu Tartu Territoriaalosakond</h3>
                        <p>
                            Asutati uue klubi ja ühiselamu Suur-Kaar 56
                        </p>
                        <div className="center">
                            <img src="https://www.tarky.ee/wp-content/uploads/2021/01/Suur-Kaar-56.jpg" alt=""/>
                        </div>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        date="4. märts 1987"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"hammer-sickle-red"}/>}
                        contentStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(233, 30, 99)' }}
                    >
                        <h3 className="vertical-timeline-element-title">Tartu Pensionäride Seltsi „Hõbeniit”</h3>
                        <p>
                            Asutatud nimetus
                        </p>
                        <div className="center">
                            <img src="https://www.tarky.ee/wp-content/uploads/2021/01/Pens-asut-1987-scaled.jpg" alt=""/>
                        </div>
                    </VerticalTimelineElement>


                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="Kevad 1994"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"estonia-coatofarms"}/>}
                    >
                        <h3 className="vertical-timeline-element-title">Tartu Kurtide Spordiselts „Kaar”</h3>
                        <p>
                            Asutatud nimetus, Kurtide Keskuse Tartu osakonna spordiselts tegevuse lõpetatud
                        </p>
                        <div className="center">
                            <img src="https://www.tarky.ee/wp-content/uploads/2021/01/kaare-logo-1.png" alt=""/>
                        </div>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="Kevad 2003"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"estonia-coatofarms"}/>}
                    >
                        <h3 className="vertical-timeline-element-title">Ühingu hoone kriis</h3>
                        <p>
                            Linnavalitsus sõlmis 20 aastaseks rendilepingu Eesti Kurtide Liiduga, asutas Tammelinna raamatukogu
                        </p>
                    </VerticalTimelineElement>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="9. märts 2012"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"estonia-coatofarms"}/>}
                    >
                        <h3 className="vertical-timeline-element-title">Tartu Noorte Seltsi „Sõprus”</h3>
                        <p>
                            Asutatud nimetus
                        </p>
                        <div className="center">
                            <img src="https://www.tarky.ee/wp-content/uploads/2021/01/IMG_5620_800x450.jpg" alt=""/>
                        </div>
                    </VerticalTimelineElement>


                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date="15. jaanuar 2019"
                        iconStyle={{ background: 'rgb(255, 255, 255)' }}
                        icon={<Icon icon={"estonia-coatofarms"}/>}
                    >
                        <h3 className="vertical-timeline-element-title">Tartu Noorte Seltsi „Sõprus”</h3>
                        <p>
                            Tegevuse lõpetati
                        </p>
                    </VerticalTimelineElement>
                </VerticalTimeline>
            </div>
        );
    }
}
