import React, {Component} from 'react';
import Icon from "../components/icon";

export default class NotFound extends Component {
    render() {
        return (
            <div className="not-found-content">
                <h1>Ei leitud</h1>
                <h1><Icon icon={'embarrassed animation'} size={'big'}/></h1>
            </div>
        );
    }
}