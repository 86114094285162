import React, {Component} from 'react';
import RouterStore from "../stores/RouterStore";
import {inject} from "mobx-react";
import Menu from "../components/menu";
import MenuItem from "../components/menu/MenuItem";
import NotFound from "./NotFound";
import WPService from "../services/WPService";
import * as moment from "moment";
import Helpers from "../utils/Helpers";
import { Roller } from "react-awesome-spinners";

interface IActionPlansProps {
    match: any;
    routing?: RouterStore;
    eventCategories?: any;
    wpService: WPService;
}
interface IActionPlansState {
   year?: number;
   eventCategory?: any;
    events?: any
}

@inject('wpService')
@inject('eventCategories')
@inject('routing')
export default class ActionPlans extends Component<IActionPlansProps, IActionPlansState> {

    private monthsGroup;
    private fromYear = 2010;
    private years = [];

    constructor(props) {
        super(props);
        this.state = {};
        this.monthsGroup = [];
        let months: any = []
        moment.months().forEach((monthName, monthIndex) => {
            months.push({name: monthName, index: monthIndex})
            if(months.length === 3) {
                this.monthsGroup.push(months);
                months = [];
            }
        });
        this.years = [];
        const currentYear = moment().year();
        for(let year = this.fromYear; year <= currentYear; year++) {
            this.years.push(year);
        }
        this.years.reverse();
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        const eventSlug = nextProps.match.params.type;
        const eventCategory = nextProps.eventCategories.filter(cat => cat.slug === eventSlug).first;
        const year = parseInt(nextProps.match.params.year);
        return {
            eventCategory,
            year: parseInt(nextProps.match.params.year),
            events: prevState.eventCategory !== eventCategory || year !== prevState.year ? null : prevState.events
        }
    }

    componentDidUpdate(prevProps: Readonly<IActionPlansProps>, prevState: Readonly<IActionPlansState>, snapshot?: any) {
        this.checkData();
    }

    componentDidMount() {
        this.checkData();
    }

    checkData() {
        if(this.state.events === null && this.state.eventCategory) {
            this.loadEvents();
        }
    }

    async loadEvents() {
        let page = 1;
        const pageSize = 100;
        let events: any = {};
        let eventLoad = true;
        while (eventLoad) {
            eventLoad = false;
            await this.props.wpService.getEvents(page, pageSize, [this.state.eventCategory.id], ['id', 'title', 'mec_start_date'], `${this.state.year}-01-01`, `${this.state.year}-12-31`).then(response => {
                response.data.map(eventData => {
                    const date = moment(eventData.mec_start_date);
                    const monthIndex = date.month();
                    if(!events[monthIndex]) {
                        events[monthIndex] = [];
                    }
                    events[monthIndex].push({
                        day: parseInt(date.format('DD')),
                        detail: eventData
                    })
                });
                eventLoad = response.data.length === pageSize;
                if(eventLoad) {
                    page++;
                }
            }).catch(() => {});
        }
        for(const key in events) {
            events[key].sort(Helpers.arrayCompareValues('day', 'ASC'))
        }
        this.setState({events})
    }

    onSelectYear(year) {
        this.props.routing.push(`/tegevusplaan/${this.props.match.params.type}/${year}`);
    }

    getType(){
        const type = this.props.match.params.type.toLowerCase();
        if(type === 'uhing') {
            return 'Ühingu'
        }
        else if(type === 'hobeniit') {
            return 'Hõbeniidi'
        }
        else if(type === 'uritused') {
            return 'ürituse'
        }
    }

    render() {
        if(!this.state.eventCategory) {
            return (
                <NotFound/>
            )
        }
        const currentMonthIndex = moment().month();
        return (
            <div>
                <h1 style={{textAlign: "center"}}>{this.getType()} tegevusplaan</h1>
                <div>
                    <Menu className={'item-inline-block'} center={true}>
                        {this.years.map(year => (
                            <MenuItem key={year} label={String(year)} border={true} color={this.state.year === year ? 'green' : 'blue'} onClick={this.onSelectYear.bind(this, year)}/>)
                        )}
                    </Menu>
                    <div style={{height: "10px"}}/>
                </div>
                {this.state.events === null && <div className={"loading center"}>
                    <Roller/>
                </div>}
                {this.state.events !== null && <div className="action-plans-calendar">
                    {this.monthsGroup.map((monthGroup, monthGroupIndex) => (
                        <div key={monthGroupIndex}>
                            {monthGroup.map((month, monthIndex) => (
                                <div key={monthIndex}>
                                    <div className={currentMonthIndex === month.index ? 'active' : ''}>
                                        <h2>{month.name}</h2>
                                        {this.state.events[month.index] && this.state.events[month.index].map((event, eventIndex) => (
                                            <p key={eventIndex}>
                                                <span className="date-day">{event.day}.</span>
                                                <span className="action-title" dangerouslySetInnerHTML={{__html: event.detail.title.rendered}}/>
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>}
            </div>
        );
    }
}
