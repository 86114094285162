import React, {Component} from 'react';
import GalleryList from "../components/gallery/GalleryList";
import RouterStore from "../stores/RouterStore";
import {inject} from "mobx-react";
import WPService from "../services/WPService";
import VideoList from "../components/video/VideoList";


interface IVideoListPageProps {
    match?: any;
    routing?: RouterStore,
}


interface IVideoListPageState {
    year: number;
}

@inject('routing')
export default class VideoListPage extends Component<IVideoListPageProps, IVideoListPageState> {
    constructor(props) {
        super(props);
        this.state = {
            year: parseInt(this.props.match.params.year)
        }
    }

    render() {
        return (
            <div className="video-list-page">
                <h1 className="center">Video {this.state.year}</h1>
                <VideoList year={this.state.year} showDate={true}/>
            </div>
        );
    }
}
