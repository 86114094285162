
export default class Helpers {

    static arrayCompareValues(key, direction: 'ASC' | 'DESC') {
        return function (a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }
            const rawA = a[key];
            const rawB = b[key];
            const varA = (typeof rawA === 'string') ? rawA.toUpperCase() : rawA;
            const varB = (typeof rawB === 'string') ? rawB.toUpperCase() : rawB;

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (direction == 'DESC') ? (comparison * -1) : comparison
            );
        };
    }

    static decamelize(str, separator){
        separator = typeof separator === 'undefined' ? '_' : separator;
        return str
            .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
            .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
            .toLowerCase();
    }

    static castBool(value) {
        if(value === null || value === undefined) {
            return false;
        }
        if(typeof(value) === 'string') {
            return value.toLowerCase() === 'true';
        }
        return Boolean(value);
    }

    static getUrn() {
        return `${window.location.pathname}${window.location.search}${window.location.hash}`;
    }

    static titleCase(string: string) {
        let sentence = string.toLowerCase().split(" ");
        for(let i = 0; i< sentence.length; i++){
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence;
    }

    static getPostThumbnailImgSrc(post) {
        const {_embedded} = post;
        if(_embedded && _embedded['wp:featuredmedia'] && _embedded['wp:featuredmedia'].first && _embedded['wp:featuredmedia'].first.media_details) {
            const featuredMedia = _embedded['wp:featuredmedia'].first;
            if(featuredMedia.media_details) {
                const mediaDetails = _embedded['wp:featuredmedia'].first.media_details;
                if(mediaDetails.sizes && mediaDetails.sizes.thumbnail && mediaDetails.sizes.thumbnail.source_url) {
                    return mediaDetails.sizes.thumbnail.source_url;
                }
            }
            return featuredMedia.source_url;
        }
        return null;
    }

}
