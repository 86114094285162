import {Component} from 'react'
import React from "react";
import {Provider} from 'mobx-react';
import BaseApiUrl from "./constants/BaseApiUrl";
import ApiClient from "./utils/ApiClient";
import PageContainer from "./pages/PageContainer";
import {Router} from "react-router";
import {syncHistoryWithStore, SynchronizedHistory} from 'mobx-react-router';
import {createBrowserHistory} from 'history';
import RouterStore from "./stores/RouterStore";
import Menu from "./components/menu";
import MenuItem from "./components/menu/MenuItem";
import WPService from "./services/WPService";
import * as moment from "moment";
import ScrollToTop from "./components/scroll-to-top";
import Icon from "./components/icon";
import {Link} from "react-router-dom";
import BackButton from "./components/back-button";
import {Button} from "react-bootstrap";
import SidebarLeft from "./components/sidebars/SidebarLeft";
import SidebarRight from "./components/sidebars/SidebarRight";
import HeaderTop from "./components/header/HeaderTop";
const YImg = require("./assets/images/y.jpg");
const mImg = require("./assets/images/m.jpg");

interface IStores {
    routing: RouterStore;
    wpService: WPService;
    categories?: any;
    eventCategories?: any;
    latestGallery?: any,
    latestVideo?: any
}

interface IAppState {
    stores: IStores,
    history: SynchronizedHistory,
    loading: boolean
    latestGallery: any,
    latestVideo: any
}

export default class App extends Component<{}, IAppState> {
    private refreshWallpaperTimeoutId;

    constructor(props) {
        super(props);
        const apiClient = new ApiClient(BaseApiUrl);
        const routingStore = new RouterStore();
        const browserHistory = createBrowserHistory();
        moment.locale('et');
        this.refreshWallpaper = this.refreshWallpaper.bind(this);
        this.state = {
            stores: {
                routing: routingStore,
                wpService: new WPService(apiClient),
                categories: []
            },
            loading: true,
            history: syncHistoryWithStore(browserHistory, routingStore),
            latestGallery: null,
            latestVideo: null
        };
        let all = [];
        all.push(this.refreshWallpaper());
        all.push(this.state.stores.wpService.getCategories().then(response => {
            this.setState({stores: {...this.state.stores, categories: response.data}});
        }));
        all.push(this.state.stores.wpService.getEventCategories().then(response => {
            this.setState({stores: {...this.state.stores, eventCategories: response.data}});
        }));
        all.push(this.state.stores.wpService.getGalleries(1, 1).then(response => {
            if(response.data && response.data.length > 0) {
                let latestGallery = response.data[0]
                this.setState({
                    latestGallery
                });
                this.state.stores.latestGallery = latestGallery;
                if(latestGallery.featured_media) {
                    return this.state.stores.wpService.getMedia(latestGallery.featured_media).then(response2 => {
                        latestGallery.thumbnail_url = response2.data.media_details.sizes.thumbnail.source_url;
                        return true;
                    });
                }
            }
        }));
        all.push(this.state.stores.wpService.getVideos(1, 1).then(response => {
            if(response.data && response.data.length > 0) {
                const latestVideo = response.data[0];
                this.setState({
                    latestVideo
                });
                this.state.stores.latestVideo = latestVideo;
            }
        }));
        Promise.all(all).then(() => {
            this.setState({loading: false});
        });
    }

    refreshWallpaper() {
        if(this.refreshWallpaperTimeoutId) {
            clearTimeout(this.refreshWallpaperTimeoutId);
        }
        return this.state.stores.wpService.getWeatherStatus().then(response => {
            document.body.className = `wallpaper-weather-${response.data.value}`;
            setTimeout(this.refreshWallpaper, 60000);
        }).catch(() => {
            setTimeout(this.refreshWallpaper, 60000);
        })
    }


    render() {
        if(this.state.loading) {
            return <div></div>
        }
        const currentYear = moment().year();
        return (
            <Provider {...this.state.stores}>
                <Router history={this.state.history}>
                    <div className="wrapper">
                        <HeaderTop />
                        <div className="content">
                            <div className="sticky-content">
                                <Menu center={true}>
                                    <MenuItem width={'250px'} color={'blue'} label={"Avaleht"} border={true} row={true} link={"/"} icon={'home'} iconPosition={'left'} />
                                    <MenuItem width={'250px'} color={'blue'} label={"OTSI"} border={true} row={true} icon={'searching'} iconPosition={'left'}/>
                                </Menu>
                                <div className="page-content">
                                    <div tabIndex={0} ref={e => e ? setTimeout(() => {
                                        e.addEventListener('blur', () => {
                                            e.focus()
                                        })
                                        e.focus()
                                    }, 1000) : undefined }>
                                        <PageContainer/>
                                        <ScrollToTop/>
                                        <BackButton/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SidebarLeft />
                        <SidebarRight />
                        {/*<div className="footer">*/}

                        {/*</div>*/}
                    </div>
                </Router>
            </Provider>
        )
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        setTimeout(() => {event.target.pauseVideo();}, 1000)
    }
}
