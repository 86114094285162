import {Component} from "react";
import * as React from "react";
import {inject} from "mobx-react";
import Menu from "../menu";
import MenuItem from "../menu/MenuItem";
import * as moment from "moment";

interface IMenuLeftProps {
    eventCategories?: any[];
}

@inject('eventCategories')
export default class MenuLeft extends Component<IMenuLeftProps, {}> {
    render() {
        const currentYear = moment().year();
        return (
            <Menu>
                <MenuItem color={'green'} label={"Ühingust"} border={true} link={"/uhingust"} icon={'walk'} iconPosition={'right'} subMenu={true} >
                    <MenuItem color={'green'} label={"EESMÄRK"} border={true} link={"/eesmark"}/>
                    <MenuItem color={'green'} label={"JUHATUS"} border={true} link={"/juhatus"} />
                    <MenuItem color={'green'} label={"PÕHIKIRI"} border={true} link={"/pohikiri"} />
                </MenuItem>
                <MenuItem color={'green'} label={"Liikmemaksud"} border={true} link={'/liikmemaksud'} icon={'money'} iconPosition={'right'} />
                <MenuItem color={'green'} label={"Tegevusplaan"} border={true} icon={'calendar-tear'} iconPosition={'right'} subMenu={true}>
                    {this.props.eventCategories.map((cat, catIndex) => (
                        <MenuItem key={catIndex} color={'green'} label={cat.name} border={true} link={`/tegevusplaan/${cat.slug}/${currentYear}`} />
                    ))}
                </MenuItem>
                <MenuItem color={'green'} label={"Ajalugu"} border={true} link={"/ajalugu"} icon={'book'} iconPosition={'right'} subMenu={true}>
                    <MenuItem color={'green'} label={"AJAJOON"} border={true} link={"/ajajoon"} />
                    <MenuItem color={'green'} label={"ESIMEHED"} border={true} subMenu={true} link={"/esimehed"} >
                        <MenuItem color={'green'} label={"Ühing"} border={true} link={"/esimehed-uhing"} />
                        <MenuItem color={'green'} label={"\"HÕBENIIT\" "} border={true} link={"/esimehed-hobeniit"} />
                        <MenuItem color={'green'} label={"\"SÕPRUS\" "} border={true} link={"/esimehed-soprus"} />
                    </MenuItem>

                    <MenuItem color={'green'} label={"Klubi läbi aegade"} border={true} subMenu={true} link={"/klubi-labi-aegade"} >
                        <MenuItem color={'green'} label={"Klubi ajalugu"} border={true} link={"/klubi-ajalugu"} subMenu={true} >
                            <MenuItem color={'green'} label={"VABARIIGI AEG"} border={true} link={"/vabariigi-aeg"} />
                            <MenuItem color={'green'} label={"OKUPATSIOONI AEG"} border={true} link={"/okupatsiooni-aeg"} />
                            <MenuItem color={'green'} label={"Taasiseseisvuse AEG"} border={true} link={"/taasiseseivuse-aeg"} />
                        </MenuItem>
                        <MenuItem color={'green'} label={"Ekskursiooni ajalugu"} border={true} link={"/ekskursiooni-ajalugu"} subMenu={true} >
                            <MenuItem color={'green'} label={"Ühingu ekskursioon"} border={true} link={"/uhingu-ekskursioon"} />
                            <MenuItem color={'green'} label={"Pensionäri ekskursioon"} border={true} link={"/pensionäri-ekskursioon"} />
                            <MenuItem color={'green'} label={"Ühingu välisreis"} border={true} link={"/uhingu-valisreis"} />
                        </MenuItem>
                        <MenuItem color={'green'} label={"Ürituse  ajalugu"} border={true} link={"/urituse-ajalugu"} subMenu={true} >
                            <MenuItem color={'green'} label={"Kurtide päeva asukohad"} border={true} link={"/kurtide-paeva-asukohad"} />
                            <MenuItem color={'green'} label={"Jõulupeo asukohad"} border={true} link={"/joulupeo-asukohad"} />
                            <MenuItem color={'green'} label={"Juubelipeo asukohad"} border={true} link={"/uhingu-juubelipeo-labi-aegade-asukohad"} />
                        </MenuItem>
                        <MenuItem color={'green'} label={"Veebilehe ajalugu"} border={true} link={"/veebi-ajalugu"} />
                    </MenuItem>
                    <MenuItem color={'green'} label={"Asukohad"} border={true} link={"/asukohad"}/>
                    <MenuItem color={'green'} label={"Ettevõte"} border={true} link={"/ettevote"} />
                    <MenuItem color={'green'} label={"Filmimehed"} border={true} link={"/filmimehed"}/>
                    <MenuItem color={'green'} label={"Esimesed"} border={true} link={"/esimesed"}/>
                    <MenuItem color={'green'} label={"Lahkunud"} border={true} link={"/lahkunud"}/>
                </MenuItem>
                <MenuItem color={'green'} label={"Teenused"} border={true} link={'/teenused'} icon={'document'} iconPosition={'right'} subMenu={true}>
                    <MenuItem color={'green'} label={"RUUMIRENT"} link={'/ruumirent'} border={true}/>
                    <MenuItem color={'green'} label={"VIIPEKEELETÕLGID"} link={'/viipekeeletolgid'}  border={true} />
                </MenuItem>
                <MenuItem color={'green'} label={"Kontakt"} border={true} link={'/kontakt'} icon={'message-sent'} iconPosition={'right'} subMenu={true}>
                    <MenuItem color={'green'} label={"AADRESS"} link={'/kontakt-aadress'} border={true}/>
                    <MenuItem color={'green'} label={"ARVELDUSKONTO"} link={'/kontakt-arvelduskonto'} border={true}/>
                </MenuItem>
            </Menu>
        )
    }
}
