import {Component} from "react";
import * as React from "react";
import YouTube from "react-youtube";
import WPService from "../../services/WPService";
import {inject} from "mobx-react";
import * as moment from "moment";
import {Modal} from "react-bootstrap";
import {parseUrl} from "query-string";
import { Roller } from "react-awesome-spinners";

interface IVideoListProps {
    year: number;
    wpService?: WPService
    showDate?: boolean;
    onClickVideo? (video): void
}

interface IVideoListState {
    postAfter: string;
    postBefore: string;
    data: any[];
    page: number;
    pageSize: number;
    postHasMore: boolean;
    selectedVideo: any;
    showVideoModal: boolean;
    videoPlayLoading: boolean;
    iframeSize: any;

}

@inject('wpService')
export default class VideoList extends Component<IVideoListProps, IVideoListState> {
    private videoDivRef: Element;

    constructor(props) {
        super(props);
        this.state = {
            postAfter: `${this.props.year - 1}-12-31T23:59:59`,
            postBefore: `${this.props.year + 1}-01-01T00:00:00`,
            data: null,
            page: 1,
            pageSize : 100,
            postHasMore: false,
            selectedVideo: null,
            showVideoModal: false,
            videoPlayLoading: false,
            iframeSize: this.calcModalIframeSize()
        }
        this.loadVideos();
        this.closeVideoModal = this.closeVideoModal.bind(this);
        this._onReady = this._onReady.bind(this);
        this.onResize = this.onResize.bind(this);
        this.videoDivRef = null;
    }

    loadVideos(page = null, pageSize=null, postAfter=null, postBefore=null, append=false) {
        if(!page) {
            page = this.state.page;
        }
        if(!pageSize) {
            pageSize = this.state.pageSize
        }
        if(!postAfter) {
            postAfter = this.state.postAfter
        }
        if(!postBefore) {
            postBefore = this.state.postBefore
        }
        this.props.wpService.getVideos(page, pageSize, null, null, postBefore, postAfter).then(response => {
            const data = append ? [...this.state.data, ...response.data] : response.data;
            data.forEach(video => {
                if(video.youtube) {
                    const url = parseUrl(video.youtube);
                    if(url.query && url.query.v) {
                        video.youtubeId = url.query.v;
                    }
                }
            });
            this.setState({
                data,
                page,
                pageSize,
                postBefore,
                postAfter,
                postHasMore: data.length >= pageSize * page
            })
        })
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    componentDidUpdate(prevProps: Readonly<IVideoListProps>, prevState: Readonly<IVideoListState>, snapshot?: any) {
        if(prevProps.year !== this.props.year) {
            const state = {
                postAfter: `${this.props.year - 1}-12-31T23:59:59`,
                postBefore: `${this.props.year + 1}-01-01T00:00:00`,
                data: null
            };
            this.setState(state);
            this.loadVideos(1, null, state.postAfter, state.postBefore);
        }
    }

    onResize(e) {
        const size = this.calcModalIframeSize();
        this.state.iframeSize.height = size.height;
        this.state.iframeSize.width = size.width;
        if(this.videoDivRef) {
            const iframeElement = this.videoDivRef.querySelector("iframe");
            if(iframeElement) {
                iframeElement.style.height = `${size.height}px`;
                iframeElement.style.width = `${size.width}px`;
            }
        }
    }

    calcModalIframeSize() {
        let height = window.innerHeight - 100;
        let width = height * (16 / 9);
        if(width > window.innerWidth) {
            width = window.innerWidth;
            height =  width / (16 / 9);
            width = height * (16 / 9);
        }
        width *= 0.8;
        height *= 0.8;
        return {width, height}
    }

    closeVideoModal() {
        this.setState({showVideoModal: false});
    }

    render() {
        if(this.state.data === null) {
            return <Roller/>;
        }
        const {selectedVideo, videoPlayLoading} = this.state;
        return (
            <div>
                <Modal
                    className={"play-video-modal" + (videoPlayLoading ? " loading" : "")}
                    show={this.state.showVideoModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={this.closeVideoModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {selectedVideo && <div dangerouslySetInnerHTML={{__html: selectedVideo.title.rendered}}/>}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div ref={e => this.videoDivRef = e}>
                            {selectedVideo && selectedVideo.youtubeId && <YouTube videoId={selectedVideo.youtubeId} opts={{
                                height: `${this.state.iframeSize.height}px`,
                                width: `${this.state.iframeSize.width}px`,
                                playerVars: {
                                    autoplay: 1,
                                },
                            }} onReady={this._onReady}
                            />}
                        </div>
                        {videoPlayLoading && <div className="loading">
                            <Roller/>
                            <p>Palun oodake, laeb!</p>
                        </div>}
                    </Modal.Body>
                </Modal>
                <div className="video-list">
                    {this.state.data.map((video, videoIndex) => (
                        <div key={videoIndex} className={"video-item"} onClick={this.itemOnClick.bind(this, video)}>
                            {video.image && <img src={video.image} alt={""}/>}
                            <div>
                                <div className={"video-title"} dangerouslySetInnerHTML={{__html: video.title.rendered}}/>
                                <div className={"postmeta"}>
                                    {this.props.showDate && <span>{moment(video.date).format('DD.MM.YYYY')}</span>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    _onReady(event) {
        this.setState({videoPlayLoading: false});
    }

    itemOnClick(video) {
        if(this.props.onClickVideo) {
            this.props.onClickVideo(video);
        }
        else {
            this.setState({selectedVideo: video, showVideoModal: true, videoPlayLoading: true});
        }
    }
}
