import React, {Component} from 'react';
import GalleryList from "../components/gallery/GalleryList";
import {inject} from "mobx-react";
import WPService from "../services/WPService";
import NotFound from "./NotFound";
import GridGallery from 'react-grid-gallery';



interface IGalleryProps {
    match?: any;
    wpService?: WPService;
}

interface IGalleryState {
    slug: string;
    data: any;
    images: any[];
}

@inject('wpService')
export default class Gallery extends Component<IGalleryProps, IGalleryState> {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            data: undefined,
            slug: this.props.match.params.slug
        }
        this.loadGallery();
    }

    loadGallery() {
        this.props.wpService.getGalleries(1, 1, null, this.state.slug).then(response => {
            const data = response.data.length > 0 ? response.data[0] : null;
            this.setState({
                data,
                images: data ? data['gallery-images'].map(image => ({
                    src: image.url,
                    thumbnail: image.thumbnail_url,
                    thumbnailWidth: image.thumbnail_width,
                    thumbnailHeight: image.thumbnail_height,
                    tags: [],
                    caption: ''
                })) : []
            });
        });
    }

    render() {
        if(this.state.data === undefined) {
            return "";
        }
        if(this.state.data === null) {
            return <NotFound/>;
        }
        return (
            <div style={{
                display: "block",
                minHeight: "1px",
                width: "100%",
                overflow: "auto"}}>
                <div className="gallery-title" dangerouslySetInnerHTML={{__html: this.state.data.title.rendered}}/>
                <GridGallery enableImageSelection={false} images={this.state.images} showImageCount={true} showLightboxThumbnails={true} backdropClosesModal={true}/>
            </div>
        );
    }
}
