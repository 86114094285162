import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import NotFound from "./NotFound";
import Index from "./Index";
import Association from "./Association";
import History from "./History";
import Services from "./Services";
import Contact from "./Contact";
import Memberships from "./Memberships";
import Links from "./Links";
import Useful from "./Useful";
import ActionPlans from "./ActionPlans";
import Archive from "./Archive";
import News from "./News";
import Gallery from "./Gallery";
import Videos from "./Videos";
import Post from "./Post";
import Page from "./Page";
import Timeline from "./Timeline";
import Media from "./Media";
import {inject} from "mobx-react";
import RouterStore from "../stores/RouterStore";
import WPService from "../services/WPService";
import MediaTop10 from "./MediaTop10";
import GalleryListPage from "./GalleryListPage";
import VideoListPage from "./VideoListPage";

interface IIndexProps {
    categories?: any,
    eventCategories?: any
}

@inject('eventCategories')
@inject('categories')
export default class PageContainer extends Component<IIndexProps, {}> {

    render() {
        return (
            <Switch>
                <Route path="/"  exact component={Index}/>
                <Route path={`/tegevusplaan/:type(${this.props.eventCategories.map(cat => cat.slug).join('|')})/:year(\\d{4})`} exact component={ActionPlans}/>
                <Route path="/arhiiv/:year"  exact component={Archive}/>
                {/*<Route path="/meedia/pildigalerii"  exact component={Gallery}/>*/}
                {/*<Route path="/meedia/videod"  exact component={Videos}/>*/}
                <Route path="/uudised-ex/:id"  exact component={News}/>
                <Route path="/meedia-top-10-:year(\d{4})"  exact component={MediaTop10}/>
                <Route path={`/:categorySlug(${this.props.categories.map(cat => cat.slug).join('|')})/:slug`} exact component={Post}/>
                <Route path="/ajajoon"  exact component={Timeline}/>
                <Route path="/meedia-pildigalerii-:year(\d{4})"  component={GalleryListPage} exact/>
                <Route path="/meedia-video-:year(\d{4})"  component={VideoListPage} exact/>
                <Route path="/galleries/:slug"  component={Gallery} exact/>
                <Route path="/meedia-:year(\d{4})"  component={Media} exact/>
                <Route path="/:slug"  exact component={Page}/>
                <Route exact path="*" component={NotFound}/>
            </Switch>
        );
    }
}
