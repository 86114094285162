import {Component, default as React} from "react";
import ImageGallery from 'react-image-gallery';

interface IHtmlContentProps {
    content: string;
    className?: string;
}

interface IHtmlContentState {
    photoIndex: number,
    isOpen: boolean,
    showVideo: any,
    showGalleryFullscreenButton:any,
    showGalleryPlayButton: any,
    showFullscreenButton: any,
    showPlayButton: any,
    content: string;
    imageItems?: Array<any>;
    elements: HTMLElement[];
    figureElements: HTMLElement[];
    figureElementSize: any;
}

export default class HtmlContent extends Component<IHtmlContentProps, IHtmlContentState> {
    private _imageGallery: any;
    private divContent: Element;

    constructor(props) {
        super(props);
        this.state = this.initState();
        this.divContent = null;
        this.divContentRef = this.divContentRef.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<IHtmlContentProps>, nextContext: any) {
        if(nextProps.content !== this.props.content) {
            this.setState(this.initState(nextProps));
        }
    }

    initState(props?: IHtmlContentProps): IHtmlContentState {
        if(!props) {
            props = this.props;
        }
        let parsedHtml = (new DOMParser()).parseFromString(props.content, 'text/html');
        const figures = parsedHtml.getElementsByTagName('figure');
        let removeFigures = [];
        let imageItems = [];
        let figureElements = [];
        for (let i = 0; i < figures.length; i++) {
            const figureElement = figures[i];
            if(figureElement.className.includes('wp-block-embed-youtube') && !figureElement.className.includes('no-belong-gallery')){
                const iframeElement = figureElement.getElementsByTagName('iframe')[0];
                const match = /embed\/([\w|-]+)(\??)/.exec(iframeElement.src);
                let imgUrl = 'https://img.youtube.com/vi/1QFd7ViK2zg/0.jpg'
                if(match) {
                    imgUrl = `https://img.youtube.com/vi/${match[1]}/0.jpg`
                }
                if(iframeElement) {
                    imageItems.push({
                        thumbnail: imgUrl,
                        original: imgUrl,
                        embedUrl: iframeElement.src,
                        description: iframeElement.title,
                        renderItem: this._renderVideo.bind(this)
                    })
                    removeFigures.push(figureElement);
                }
            }
            else if(figureElement.className.includes('wp-block-gallery') && !figureElement.className.includes('no-belong-gallery')) {
                if(figureElement.className.includes('gallery-object-selection')) {
                    let elements = figureElement.parentElement.querySelectorAll('[class*="select-gallery-image-object-"]');
                    for(let j = 0; j < elements.length; j++) {
                        let currentElement = elements[j];
                        let selectIndex = null;
                        currentElement.classList.forEach(className => {
                            if(className.startsWith('select-gallery-image-object-')) {
                                selectIndex = className.replace('select-gallery-image-object-', '');
                            }
                        });
                        if(selectIndex) {
                            const toggleClassName = `selected-object-${selectIndex}`;
                            currentElement.addEventListener('mouseover', () => {
                                figureElement.classList.add(toggleClassName);
                            });
                            currentElement.addEventListener('mouseout', () => {
                                figureElement.classList.remove(toggleClassName);
                            });
                        }
                    }
                }
                else {
                    const figureChildren = figureElement.getElementsByTagName('figure')
                    for(let j = 0; j < figureChildren.length; j++) {
                        const img = figureChildren[j].getElementsByTagName('img')[0];
                        if(img) {
                            imageItems.push({
                                src: img.src,
                                description: img.alt,
                                original: img.src,
                                thumbnail: img.src,
                            });
                        }
                    }
                    removeFigures.push(figureElement);
                }
            }
            else {
                figureElements.push(figureElement);
            }
        }
        if(removeFigures.length > 0) {
            removeFigures.forEach(f => f.remove());
        }
        else {
            imageItems = [];
        }

        let elements = [];

        for(let i = 0; i < parsedHtml.body.children.length; i++) {
            elements.push(parsedHtml.body.children[i]);
        }

        return {
            photoIndex: 0,
            isOpen: false,
            showVideo: {},
            showGalleryFullscreenButton: true,
            showGalleryPlayButton: false,
            showFullscreenButton: true,
            showPlayButton: false,
            content: parsedHtml.body.innerHTML,
            elements,
            imageItems,
            figureElements,
            figureElementSize: this.calcFigureElementSize()
        }
    }

    _toggleShowVideo(url) {
        this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
        this.setState({
            showVideo: this.state.showVideo
        });

        if (this.state.showVideo[url]) {
            if (this.state.showPlayButton) {
                this.setState({showGalleryPlayButton: false});
            }

            if (this.state.showFullscreenButton) {
                this.setState({showGalleryFullscreenButton: false});
            }
        }
    }


    _renderVideo(item) {
        return (
            <div>
                {
                    this.state.showVideo[item.embedUrl] ?
                        <div className='video-wrapper'>
                            <a
                                className='close-video'
                                onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                            >
                            </a>
                            <iframe
                                width='560'
                                height='315'
                                src={item.embedUrl}
                                frameBorder='0'
                                allowFullScreen
                            >
                            </iframe>
                        </div>
                        :
                        <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
                            <div className="play-button"/>
                            <img className="image-gallery-image" src={item.original} />
                            {
                                item.description &&
                                <span
                                    className='image-gallery-description'
                                    style={{right: '0', left: 'initial'}}
                                >
                    {item.description}
                  </span>
                            }
                        </a>
                }
            </div>
        );
    }

    _resetVideo() {
        this.setState({showVideo: {}});

        if (this.state.showPlayButton) {
            this.setState({showGalleryPlayButton: true});
        }

        if (this.state.showFullscreenButton) {
            this.setState({showGalleryFullscreenButton: true});
        }
    }

    componentDidMount() {
        $(".eb-image-comparison-wrapper").each(function () {
            let _this = $(this),
                overlay = _this.attr("data-overlay") === "true",
                hover = _this.attr("data-hover") === "true",
                position = _this.attr("data-position"),
                beforeLabel = _this.attr("data-before-label"),
                afterLabel = _this.attr("data-after-label"),
                lineColor = _this.attr("data-line-color"),
                lineWidth = _this.attr("data-line-width"),
                arrowColor = _this.attr("data-arrow-color");

            // @ts-ignore
            _this.imagesLoaded(function () {
                // @ts-ignore
                _this.twentytwenty({
                    default_offset_pct: position,
                    no_overlay: !overlay,
                    before_label: beforeLabel,
                    after_label: afterLabel,
                    move_slider_on_hover: hover,
                    click_to_move: true,
                });

                let handle = _this.find(".twentytwenty-handle");

                // Add line markup
                handle.prepend('<div class="handle-before"></div>');
                handle.append('<div class="handle-after"></div>');

                // Selectors
                let handleBefore = _this.find(".handle-before");
                let handleAfter = _this.find(".handle-after");
                let leftArrow = _this.find(".twentytwenty-left-arrow");
                let rightArrow = _this.find(".twentytwenty-right-arrow");

                // Apply styles
                handle.css({
                    border: `${lineWidth}px solid ${lineColor}`,
                });

                handleBefore.css({
                    "background-color": lineColor,
                    width: lineWidth,
                });

                handleAfter.css({
                    "background-color": lineColor,
                    width: lineWidth,
                });

                leftArrow.css({ "border-right-color": arrowColor });
                rightArrow.css({ "border-left-color": arrowColor });
            });
        });
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize(e) {
        const size = this.calcFigureElementSize();
        this.state.figureElementSize.height = size.height;
        this.state.figureElementSize.width = size.width;
        for(let element of this.state.figureElements) {
            const iframeElement = element.querySelector("iframe");
            if(iframeElement){
                iframeElement.style.height = `${this.state.figureElementSize.height}px`;
                iframeElement.style.width = `${this.state.figureElementSize.width}px`;
            }
        }
    }

    calcFigureElementSize() {
        if(!this.divContent) {
            return {width: 0, height: 0};
        }
        let width = this.divContent.clientWidth;
        let height =  width / (16 / 9);
        width = height * (16 / 9);
        return {width, height};
    }

    componentDidUpdate(prevProps: Readonly<IHtmlContentProps>, prevState: Readonly<IHtmlContentState>, snapshot?: any) {
        if(this.state.elements !== prevState.elements) {
            this.refreshContent();
        }
    }

    render() {
        return (
            <div className={this.props.className}>
                <div ref={this.divContentRef}
                 dangerouslySetInnerHTML={{__html: this.state.content}}

                />
                <div className="clear"/>
                {this.state.imageItems && this.state.imageItems.length > 0 && <ImageGallery
                    ref={i => this._imageGallery = i}
                    items={this.state.imageItems}
                    showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
                    showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
                />}
            </div>
        );
    }

    refreshContent() {
        if(this.divContent) {
            let child = this.divContent.lastElementChild;
            while (child) {
                this.divContent.removeChild(child);
                child = this.divContent.lastElementChild;
            }

            for(let element of this.state.elements) {
                this.divContent.appendChild(element);
            }

            for(let element of this.state.figureElements) {
                const iframeElement = element.querySelector("iframe");
                if(iframeElement){
                    iframeElement.style.height = `${this.state.figureElementSize.height}px`;
                    iframeElement.style.width = `${this.state.figureElementSize.width}px`;
                }
            }
        }
    }

    divContentRef(e) {
        const needCalc = !this.divContent && e;
        this.divContent = e;
        if(needCalc) {
            const size = this.calcFigureElementSize();
            this.state.figureElementSize.height = size.height;
            this.state.figureElementSize.width = size.width;
        }
        this.refreshContent();
    }
}
