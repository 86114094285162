import {Component, default as React} from "react";
import Icon from "../icon";
import Lightbox from 'react-image-lightbox';
import YouTube from 'react-youtube';
import ImageGallery from 'react-image-gallery';

const PREFIX_URL = 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/';


interface INewsDetailProps {
    id: number,
    icon?: string
    title: any,
    imgSrc?: string,
    content: any,
    onBackClick: (e: any) => void
}

const images = [
    '//placekitten.com/1500/500',
    '//placekitten.com/4000/3000',
    '//placekitten.com/800/1200',
    '//placekitten.com/1500/1500',
];

interface INewsDetailState {
    photoIndex: number,
    isOpen: boolean,
    showVideo: any,
    showGalleryFullscreenButton:any,
    showGalleryPlayButton: any,
    showFullscreenButton: any,
    showPlayButton: any

}


export default class NewsDetail extends Component<INewsDetailProps, INewsDetailState> {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            showVideo: {},
            showGalleryFullscreenButton: false,
            showGalleryPlayButton: false,
            showFullscreenButton: false,
            showPlayButton: false
        };
        this.images1 = [
            {
                thumbnail: `${PREFIX_URL}4v.jpg`,
                original: `${PREFIX_URL}4v.jpg`,
                embedUrl: 'https://www.youtube.com/embed/4pSzhZ76GdM?autoplay=1&showinfo=0',
                description: 'Render custom slides within the gallery',
                renderItem: this._renderVideo.bind(this)
            },
            {
                original: 'https://picsum.photos/id/1018/1000/600/',
                thumbnail: 'https://picsum.photos/id/1018/250/150/',
            },
            {
                original: 'https://picsum.photos/id/1015/1000/600/',
                thumbnail: 'https://picsum.photos/id/1015/250/150/',
            },
            {
                original: 'https://picsum.photos/id/1019/1000/600/',
                thumbnail: 'https://picsum.photos/id/1019/250/150/',
            },
            {
                original: 'https://picsum.photos/id/1019/1000/600/',
                thumbnail: 'https://picsum.photos/id/1019/250/150/',
            },
        ]

    }
    images1 = [];

    _toggleShowVideo(url) {
        this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
        this.setState({
            showVideo: this.state.showVideo
        });

        if (this.state.showVideo[url]) {
            if (this.state.showPlayButton) {
                this.setState({showGalleryPlayButton: false});
            }

            if (this.state.showFullscreenButton) {
                this.setState({showGalleryFullscreenButton: false});
            }
        }
    }


    _renderVideo(item) {
        return (
            <div>
                {
                    this.state.showVideo[item.embedUrl] ?
                        <div className='video-wrapper'>
                            <a
                                className='close-video'
                                onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                            >
                            </a>
                            <iframe
                                width='560'
                                height='315'
                                src={item.embedUrl}
                                frameBorder='0'
                                allowFullScreen
                            >
                            </iframe>
                        </div>
                        :
                        <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
                            <div className='play-button'></div>
                            <img className='image-gallery-image' src={item.original} />
                            {
                                item.description &&
                                <span
                                    className='image-gallery-description'
                                    style={{right: '0', left: 'initial'}}
                                >
                    {item.description}
                  </span>
                            }
                        </a>
                }
            </div>
        );
    }

    _resetVideo() {
        this.setState({showVideo: {}});

        if (this.state.showPlayButton) {
            this.setState({showGalleryPlayButton: true});
        }

        if (this.state.showFullscreenButton) {
            this.setState({showGalleryFullscreenButton: true});
        }
    }


    render() {
        const {icon, title, imgSrc, content, onBackClick} = this.props;
        const { photoIndex, isOpen } = this.state;
        return (
            <div className="news-detail">
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
                <h2 className="news-item-header">
                    {icon && <Icon icon={icon} size={'big'}/>}
                    {title}
                </h2>
                <div className="news-item-content">
                    {imgSrc && <img className="pointer" src={imgSrc} alt={""} onClick={() => this.setState({isOpen: true})}/>}
                    {content}
                </div>
                <div className="clear"/>

                {onBackClick && <div onClick={onBackClick} className="pointer">{"<-"} Tagasi</div>}
                <ImageGallery
                    items={this.images1}
                />
            </div>
        );
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
}