import {Component} from "react";
import * as React from "react";
import Icon from "../icon";
import MenuItem from "../menu/MenuItem";
import * as moment from "moment";
import {inject} from "mobx-react";
import Helpers from "../../utils/Helpers";

interface IPostProps {
    id: number,
    icon?: string
    title: any,
    imgSrc?: string,
    excerpt: any,
    date?: any;
    categories: Array<any>;
    _embedded?: any;
    onClick?: (e: any) => void
}

export default class PostItem extends Component<IPostProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        const {categories, title, excerpt, onClick} = this.props;
        const imgSrc = Helpers.getPostThumbnailImgSrc(this.props);
        return (
            <div className="news-item pointer" onClick={onClick}>
                <div className="news-item-content">
                    {imgSrc && <img src={imgSrc} alt={""}/>}
                    <h2 className="news-item-header" dangerouslySetInnerHTML={{__html: title.rendered}}/>
                    <div>
                        <div className={"postmeta"}>
                            <span>Postitatud: {moment(this.props.date).format('DD.MM.YYYY')}</span>
                            <span>Kategooria: {categories.map(cat => cat.name).join(', ')}</span>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: excerpt.rendered}}/>
                </div>
                <div className="right-button">
                    <MenuItem icon={'double-tap'} iconPosition={'right'} color={'blue'} label={"Loe edasi"} border={true} onClick={onClick} />
                </div>
                <div className="clear"/>
            </div>
        );
    }
}
