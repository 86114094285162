import React, {Component} from 'react';
import NotFound from "./NotFound";
import PostDetail from "../components/post/PostDetail";
import RouterStore from "../stores/RouterStore";
import {inject} from "mobx-react";
import WPService from "../services/WPService";

interface IPostProps {
    match: any,
    routing?: RouterStore,
    wpService?: WPService,
    categories?: Array<any>
}

interface IPostState {
    data?: any
}

@inject('routing')
@inject('wpService')
@inject('categories')
export default class Post extends Component<IPostProps, IPostState> {

    constructor(props) {
        super(props);
        this.state = {};
        const category = props.categories.filter(cat => cat.slug === props.match.params.categorySlug).first;
        this.props.wpService.getPosts(1, 1, [category.id], props.match.params.slug).then(response => {
            this.setState({data: response.data.length === 0 ? null : response.data[0]});
        });
    }


    render() {
        if(this.state.data === undefined) {
            return "";
        }
        if (this.state.data === null) {
            return <NotFound/>
        }
        return <PostDetail data={this.state.data}/>
    }

    goToIndex() {
        this.props.routing.goBack();
    }
}