import {Component} from "react";
import * as React from "react";
import Icon from "../icon";
import {inject} from "mobx-react";
import RouterStore from "../../stores/RouterStore";

interface IBackButtonProps {
    routing?: RouterStore;
}

interface IBackButtonState {
    active: boolean;
}

@inject('routing')
export default class BackButton extends Component<IBackButtonProps, IBackButtonState> {
    private element: HTMLElement = null;

    constructor(props) {
        super(props);
        this.click = this.click.bind(this);
        this.state = {
            active: this.props.routing.history.length > 2
        };
        this.historyEvent = this.historyEvent.bind(this);
        // @ts-ignore
        this.props.routing.history.subscribe(this.historyEvent)

    }

    historyEvent(e) {
        const active = this.props.routing.history.length > 2;
        if(this.state.active !== active) {
            this.setState({active});
        }
    }

    componentDidMount() {
        // @ts-ignore
        this.props.routing.history.subscribe(this.historyEvent);
    }

    componentWillUnmount() {
        // @ts-ignore
        this.props.routing.history.unsubscribe(this.historyEvent);
    }

    render() {
        if(!this.state.active) {
            return null;
        }
        return (
            <div className="history-back-button" onClick={this.click}>
                <Icon icon="right"/>
            </div>
        )
    }

    click() {
        try {
            this.props.routing.goBack();
        }
        catch (e) {
            this.setState({active: false});
        }
    }
}
